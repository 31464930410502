import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'gatsby';
import Slider from 'infinite-react-carousel';
import Breakpoints from '@utils/breakpoints';

// import IconStrong from '@static/icons/strong.svg';
import IconFlexi from '@static/icons/flexi.svg';

import Button from '@components/Button/Button';

import Styles from './HomeLessons.styles';

const useStyles = createUseStyles(Styles);

const cleanBreakPoint = b => {
  return parseInt(b.replace('px', ''), 10);
};
const getDefaultSlidesToShow = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < cleanBreakPoint(Breakpoints.s) ? 1 : 2;
  } else {
    return 2;
  }
};

const HomeLessons = props => {
  const classes = useStyles(props);

  const defaultSettings = {
    slidesToShow: getDefaultSlidesToShow(),
    autoplay: typeof window !== 'undefined' ? window.innerWidth <= 900 : true,
    arrows: false,
    arrowsBlock: false,
    dots: true,
    // centerMode: true,
  };

  const [settings, handleSettings] = useState(defaultSettings);

  /* eslint-disable */
  useEffect(() => {
    const checkSizes = () => {
      const clonedSettings = JSON.parse(JSON.stringify(settings));
      if (window.innerWidth < cleanBreakPoint(Breakpoints.s)) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 1;
        handleSettings(clonedSettings);
      } else {
        clonedSettings.autoplay = false;
        clonedSettings.slidesToShow = 2;
        handleSettings(clonedSettings);
      }
    };

    window.addEventListener('resize', checkSizes);

    return function cleanup() {
      window.removeEventListener('resize', checkSizes);
    };
  }, []);
  /* eslint-enable */

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3 className={classes.title}>LiveFlow di Tina Olsen</h3>
        <p className={classes.subtitle}>
          Percorsi personalizzati online dal vivo per fare pace con corpo e
          cibo!
        </p>
        <div className={classes.categories}>
          <div className="carousel_dark">
            <Slider {...settings}>
              <div className={classes.category}>
                <h3 className={classes.categoryTitle}>Consulenza Gratuita</h3>
                <p>
                  Liberati finalmente dalla mentalità della dieta e trovare la
                  pace con il corpo e il cibo ti invito a prenotare qui una
                  sessione gratuita.{' '}
                </p>
                <div className={classes.categoryPrice}>GRATIS</div>

                <Button
                  to="/lezioni"
                  label="PRENOTA CONSULENZA"
                  type="outline"
                  transparent
                />
              </div>
              <div className={classes.category}>
                <h3 className={classes.categoryTitle}>Movimento Intuitivo</h3>
                <p>
                  Durante una lezione di Movimento Intuitivo puoi riscoprire il
                  piacere di muoverti e imparare ad ascoltare nuovamente il tuo
                  corpo.{' '}
                </p>
                <div className={classes.categoryPrice}>DA 10.00€</div>

                <Button
                  to="/lezioni"
                  label="PRENOTA LEZIONE"
                  type="outline"
                  transparent
                />
              </div>
            </Slider>
          </div>
        </div>
        {/* <div className={classes.linkContainer}>
          <Link className={classes.link} to="/lezioni">
            TUTTE LE LEZIONI
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default HomeLessons;

import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
// import Slider from 'infinite-react-carousel';
import Breakpoints from '@utils/breakpoints';

import instructors from '@data/instructors.json';

import Styles from './Instructors.styles';

const useStyles = createUseStyles(Styles);

const cleanBreakPoint = b => {
  return parseInt(b.replace('px', ''), 10);
};
const getDefaultSlidesToShow = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= cleanBreakPoint(Breakpoints.m) &&
      window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ? 2
      : window.innerWidth <= cleanBreakPoint(Breakpoints.s)
      ? 1
      : 4;
  } else {
    return 4;
  }
};

const Instructors = props => {
  const classes = useStyles(props);

  const defaultSettings = {
    slidesToShow: getDefaultSlidesToShow(),
    autoplay: false,
    arrows: true,
    arrowsBlock: true,
    centerMode: true,
  };
  const [settings, handleSettings] = useState(defaultSettings);

  /* eslint-disable */
  useEffect(() => {
    const checkSizes = () => {
      const clonedSettings = JSON.parse(JSON.stringify(settings));
      if (
        window.innerWidth <= cleanBreakPoint(Breakpoints.m) &&
        window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 2;
        handleSettings(clonedSettings);
      } else if (window.innerWidth <= cleanBreakPoint(Breakpoints.s)) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 1;
        handleSettings(clonedSettings);
      } else {
        clonedSettings.autoplay = false;
        clonedSettings.slidesToShow = 4;
        handleSettings(clonedSettings);
      }
    };

    window.addEventListener('resize', checkSizes);

    return function cleanup() {
      window.removeEventListener('resize', checkSizes);
    };
  }, []);
  /* eslint-enable */

  const i = instructors.data.filter(el => el.id === '0000007')[0];
  const image = require(`@static/img/${i.imgHome}`).default;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3 className={classes.title}>Chi sono</h3>
        <p className={classes.subtitle}>
          Da quando avevo 9 anni ho capito di “dover fare attenzione” a cosa
          mangiavo per non ingrassare. E così, per quasi 40 anni mi sono fatta
          guidare dalla cultura della dieta, all'inizio per dimagrire e poi per
          paura di ingrassare, senza mai chiedermi se il controllo,
          l'allenamento ossessivo, il pianificare ogni pasto, il contare
          calorie, le restrizioni, la deprivazione e la rinuncia a cene
          improvvisate o a una fetta di torta appena sfornata, valeva veramente
          la pena. <br />
          <a href="/chi-sono" style={{ color: '#FF976C' }}>
            Continua a leggere...
          </a>
        </p>
        <div className={classes.carousel}>
          {/* <Slider {...settings}>
            {instructors.data
              .filter(el => el.id === '0000007')
              .map(i => {
                const image = require(`@static/img/${i.imgHome}`).default;
                return (
                  <div key={i.url} className={classes.itemContainer}>
                    <Link to={i.url}>
                      <div
                        className={classes.item}
                        style={{
                          background: `url(${image}) center center no-repeat`,
                          backgroundSize: 'cover',
                        }}
                      ></div>
                      <div className={classes.itemBg}></div>
                      <h3 className={classes.itemTxt}>{i.name}</h3>
                    </Link>
                  </div>
                );
              })}
          </Slider> */}
          <div className={classes.itemContainer}>
            <Link to={i.url}>
              <div
                className={classes.item}
                style={{
                  background: `url(${image}) center center no-repeat`,
                  backgroundSize: 'cover',
                }}
              ></div>
              <div className={classes.itemBg}></div>
              <h3 className={classes.itemTxt}>{i.name}</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructors;

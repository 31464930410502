import Breakpoints from '@utils/breakpoints';
import Utils from '@utils/';

const Styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '430px',
    position: 'relative',
    transition: Utils.transition('height', '.2s'),
    [`@media (max-width: ${Breakpoints.m})`]: {
      height: '690px',
      flexDirection: 'column',
    },
    [`@media (max-width: ${Breakpoints.xs})`]: {
      height: '600px',
    },
  },
  image: {
    width: '63%',
    height: '100%',
    [`@media (max-width: ${Breakpoints.m})`]: {
      display: 'none',
    },
  },
  imageMobile: {
    display: 'none',
    [`@media (max-width: 460px)`]: {
      display: 'block',
      height: '60%',
    },
  },
  imageTablet: {
    display: 'none',
    [`@media (max-width: ${Breakpoints.m})`]: {
      display: 'block',
      height: '420px',
    },
    [`@media (max-width: 460px)`]: {
      display: 'none',
    },
  },
  wave: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '614px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
    },
  },
  waveContent: {
    position: 'relative',
    zIndex: 200,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 100px',
    [`@media (max-width: ${Breakpoints.l})`]: {
      padding: '0 60px',
    },
    [`@media (max-width: ${Breakpoints.m})`]: {
      padding: '0 40px',
      height: '48%',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '0 20px',
    },
    [`@media (max-width: ${Breakpoints.xs})`]: {
      height: '55%',
    },
  },
  waveContentText: {
    width: '385px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
    },
  },
  waveImage: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    [`@media (max-width: ${Breakpoints.m})`]: {
      display: 'none',
    },
  },
  waveImageTablet: {
    display: 'none',
    [`@media (max-width: ${Breakpoints.m})`]: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      top: '-70px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
    [`@media (max-width: 460px)`]: {
      display: 'none',
    },
  },
  waveImageMobile: {
    display: 'none',
    [`@media (max-width: 460px)`]: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      top: '-80px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
    [`@media (max-width: 360px)`]: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      top: '-40px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
  },
  title: {
    fontSize: '40px',
    lineHeight: 1,
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '26px',
      marginBottom: '8px',
      lineHeight: '1.2',
      marginTop: '30px',
    },
  },
  subtitle: {
    fontSize: '20px',
    marginBottom: '30px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '14px',
      marginBottom: '20px',
      marginTop: '0',
    },
  },
};

export default Styles;

import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Slider from 'infinite-react-carousel';
import Breakpoints from '@utils/breakpoints';

import Pagamenti from '@static/img/pagamenti-sicuri.svg';
import Allenamenti from '@static/img/allenamenti-person.svg';
import Diretta from '@static/img/lezioni-diretta.svg';
import Qualificati from '@static/img/insegnanti-qualificati.svg';

import Styles from './Benefits.styles';

const useStyles = createUseStyles(Styles);

const cleanBreakPoint = b => {
  return parseInt(b.replace('px', ''), 10);
};
const getDefaultSlidesToShow = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= cleanBreakPoint(Breakpoints.l) &&
      window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ? 2
      : window.innerWidth <= cleanBreakPoint(Breakpoints.s)
      ? 1
      : 4;
  } else {
    return 4;
  }
};

const Benefits = props => {
  const classes = useStyles(props);

  const defaultSettings = {
    slidesToShow: getDefaultSlidesToShow(),
    autoplay: typeof window !== 'undefined' ? window.innerWidth <= 900 : true,
    arrows: false,
    arrowsBlock: false,
  };

  const [settings, handleSettings] = useState(defaultSettings);

  /* eslint-disable */
  useEffect(() => {
    const checkSizes = () => {
      const clonedSettings = JSON.parse(JSON.stringify(settings));
      if (
        window.innerWidth <= cleanBreakPoint(Breakpoints.l) &&
        window.innerWidth > cleanBreakPoint(Breakpoints.s)
      ) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 2;
        handleSettings(clonedSettings);
      } else if (window.innerWidth <= cleanBreakPoint(Breakpoints.s)) {
        clonedSettings.autoplay = true;
        clonedSettings.slidesToShow = 1;
        handleSettings(clonedSettings);
      } else {
        clonedSettings.autoplay = false;
        clonedSettings.slidesToShow = 4;
        handleSettings(clonedSettings);
      }
    };

    window.addEventListener('resize', checkSizes);

    return function cleanup() {
      window.removeEventListener('resize', checkSizes);
    };
  }, []);
  /* eslint-enable */

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.carousel}>
          <div>
            <Slider {...settings}>
              <div className={classes.item}>
                <img
                  className={classes.itemImg}
                  src={Pagamenti}
                  alt="Pagamenti sicuri"
                />
                <h3 className={classes.itemText}>PAGAMENTI SICURI</h3>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.itemImg}
                  src={Allenamenti}
                  alt="Percorsi personalizzati"
                />
                <h3 className={classes.itemText}>Percorsi personalizzati</h3>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.itemImg}
                  src={Diretta}
                  alt="Online, in diretta"
                />
                <h3 className={classes.itemText}>Online, in diretta</h3>
              </div>
              <div className={classes.item}>
                <img
                  className={classes.itemImg}
                  src={Qualificati}
                  alt="COUNSELOR CERTIFICATA"
                />
                <h3 className={classes.itemText}>COUNSELOR certificata</h3>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

import Breakpoints from '@utils/breakpoints';
import Dimensions from '@utils/dimensions';
import Utils from '@utils/';

const Styles = {
  container: {
    marginTop: '40px',
    marginBottom: '60px',
    padding: '0 20px',
  },
  content: {
    maxWidth: Dimensions.contentMaxWidth,
    margin: '0 auto',
  },
  title: {
    fontSize: '32px',
    marginBottom: '10px',
    marginTop: '0',
    textAlign: 'center',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '22px',
    },
  },
  blog: {
    marginTop: '60px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  left: {
    width: '50%',
    display: 'flex',
    paddingRight: '10px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      paddingRight: 0,
      paddingBottom: '10px',
    },
  },
  right: {
    width: '50%',
    paddingLeft: '10px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '100%',
      paddingLeft: 0,
      paddingTop: '10px',
    },
  },
  firstBlog: {
    transition: Utils.transition('opacity', '.2s'),
    '&:hover': {
      opacity: '0.7',
    },
  },
  firstBlogTitle: {
    fontSize: '28px',
    margin: '10px 0',
    fontWeight: 400,
  },
  firstBlogDescription: {
    fontSize: '14px',
  },
  firstBlogLinkContainer: {
    textAlign: 'right',
    paddingTop: '10px',
  },
  firstBlogLink: {
    fontSize: '14px',
  },
  secondBlog: {
    display: 'block',
    transition: Utils.transition('opacity', '.2s'),
    '&:hover': {
      opacity: '0.7',
    },
  },
  secondBlogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  secondBlogImage: {
    width: '60%',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
    },
  },
  secondBlogText: {
    width: '40%',
    paddingLeft: '20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  secondBlogTitle: {
    fontSize: '28px',
    margin: '0 0 10px 0',
    fontWeight: 400,
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginTop: '10px',
    },
  },
  secondBlogDescription: {
    fontSize: '14px',
  },
  secondBlogLinkContainer: {
    textAlign: 'right',
    paddingTop: '10px',
  },
  secondBlogLink: {
    fontSize: '14px',
  },
  top: {
    paddingBottom: '10px',
  },
  bottom: {
    paddingTop: '10px',
  },
  thirdBlog: {
    display: 'block',
    transition: Utils.transition('opacity', '.2s'),
    '&:hover': {
      opacity: '0.7',
    },
  },
  thirdBlogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [`@media (max-width: ${Breakpoints.m})`]: {
      flexDirection: 'row',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      flexDirection: 'column',
    },
  },
  thirdBlogImage: {
    width: '60%',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
    },
  },
  thirdBlogText: {
    width: '60%',
    [`@media (max-width: ${Breakpoints.m})`]: {
      width: '40%',
      paddingLeft: '20px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  thirdBlogTitle: {
    fontSize: '28px',
    margin: '10px 0',
    fontWeight: 400,
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginTop: '0',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      marginTop: '10px',
    },
  },
  thirdBlogDescription: {
    fontSize: '14px',
  },
  thirdBlogLinkContainer: {
    textAlign: 'right',
    paddingTop: '10px',
  },
  thirdBlogLink: {
    fontSize: '14px',
  },
};

export default Styles;

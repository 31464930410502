import Breakpoints from '@utils/breakpoints';
import Colors from '@utils/colors.js';

const Styles = {
  container: {
    width: '100%',
    borderBottom: `30px solid ${Colors.alternateBg}`,
  },
  content: {
    margin: '0 auto',
    padding: '40px 20px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      maxWidth: '600px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      maxWidth: '100%',
      padding: '20px',
    },
  },
  item: {
    textAlign: 'center',
  },
  itemImg: {
    height: '50px',
    width: 'auto !important',
    margin: '0 auto',
  },
  itemText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    margin: '20px 0 0 0',
  },
};

export default Styles;

import { Link } from 'gatsby';
import React from 'react';
import { createUseStyles } from 'react-jss';

import Studio from '@data/studio.json';
import Styles from './BestStudio.styles';

const useStyles = createUseStyles(Styles);

const BestStudio = props => {
  const classes = useStyles(props);

  const renderStudio = (a, b) => {
    return Studio.data.slice(a, b).map(el => {
      return (
        <p key={el.url} className={classes.link}>
          <Link className={classes.linkG} to={el.url}>
            {el.name}
          </Link>
        </p>
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3 className={classes.title}>I migliori studi di Pilates</h3>
        <div className={classes.links}>
          <div className={classes.col}>{renderStudio(0, 5)}</div>
          <div className={classes.col}>{renderStudio(5, 10)}</div>
          <div className={classes.col}>{renderStudio(10, 15)}</div>
          <div className={classes.col}>{renderStudio(15, 20)}</div>
        </div>
      </div>
    </div>
  );
};

export default BestStudio;

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';
import Hero from '@components/Hero/Hero';
// import HomeOffer from '@components/HomeOffer/HomeOffer';
import Benefits from '@components/Benefits/Benefits';
import HomeLessons from '@components/HomeLessons/HomeLessons';
import Instructors from '@components/Instructors/Instructors';
import Reviews from '@components/Reviews/Reviews';
import HomeBlog from '@components/HomeBlog/HomeBlog';
import Newsletter from '@components/Newsletter/Newsletter';
import BestStudio from '@components/BestStudio/BestStudio';

const IndexPage = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <Seo title="Liveflow" url={location.pathname} />
      <Hero />
      <Benefits />
      {/* <HomeOffer /> */}
      <HomeLessons />
      <Instructors />
      <Reviews />
      <HomeBlog posts={posts} />
      <Newsletter />
      {/* <BestStudio /> */}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomeBlogPosts {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { title: { ne: "" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            date(formatString: "YYYY-MM-DD")
            title
            category
            image
            description
          }
          html
        }
      }
    }
  }
`;

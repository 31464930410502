import Colors from '@utils/colors.js';
import Dimensions from '@utils/dimensions';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    background: Colors.alternateBg,
    padding: '60px 20px',
    textAlign: 'center',
  },
  content: {
    maxWidth: Dimensions.contentBlogWidth,
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    margin: '0 0 10px 0',
    fontSize: '32px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '28px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '22px',
    },
  },
  subtitle: {
    margin: 0,
    fontSize: '20px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '16px',
    },
  },
  categories: {
    width: '100%',
    margin: '80px 0',
    [`@media (max-width: ${Breakpoints.m})`]: {
      margin: '40px 0',
    },
  },
  category: {
    textAlign: 'center',
    width: '40%',
    padding: '0 20px',
  },
  categoryTitle: {
    fontWeight: 700,
    margin: 0,
  },
  categoryIcon: {
    margin: '10px auto 30px',
  },
  categoryPrice: {
    fontWeight: 700,
    color: Colors.accent,
    marginBottom: '25px',
  },
  categoryLevel: {
    margin: '30px 0',
    fontWeight: 700,
  },
  linkContainer: {
    marginTop: '20px',
  },
  link: {
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
};

export default Styles;

import React from 'react';
import { createUseStyles } from 'react-jss';

import Wave from '@static/img/onda-review.svg';
import WaveMobile from '@static/img/onda-review-mobile.svg';
import Slider from 'infinite-react-carousel';

//import Avatar from '@static/img/avatar-large-square.jpg';

import Styles from './Reviews.styles';

const useStyles = createUseStyles(Styles);

const Reviews = props => {
  const classes = useStyles(props);

  const settings = {
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    arrowsBlock: false,
    dots: true,
  };

  return (
    <div className={classes.container}>
      <img src={Wave} className={classes.bg} alt="Dicono di me" />
      <img src={WaveMobile} className={classes.bgMobile} alt="Dicono di me" />
      <div className={classes.content}>
        <div className={classes.block}>
          <h3 className={classes.title}>Dicono di me</h3>
          <div className={classes.carousel}>
            <Slider {...settings}>
              <div className={classes.itemContainer}>
                <div className={classes.itemText}>
                  Sono stata contentissima di aver partecipato!!! Un'esperienza
                  davvero bella e stimolante, che non mi ha fatto sentire
                  inadeguata per il mio corpo come spesso accade. Grazie mille!!
                </div>
                {/* <div
                  className={classes.itemAvatar}
                  style={{
                    background: `url(${Avatar}) no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                ></div> */}
                <div className={classes.itemSignature}>
                  Darani, Sant'Ilario d'Enza
                </div>
              </div>

              <div className={classes.itemContainer}>
                <div className={classes.itemText}>
                  L'attenzione, la gentilezza e la professionalità di Tina fanno
                  bene sia al corpo che all'anima. Si trascorre un'ora in
                  serenità facendo qualcosa per se stessi. Ed è bello.
                </div>
                {/* <div
                  className={classes.itemAvatar}
                  style={{
                    background: `url(${Avatar}) no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                ></div> */}
                <div className={classes.itemSignature}>Mariel, Piemonte</div>
              </div>

              <div className={classes.itemContainer}>
                <div className={classes.itemText}>
                  È stata un'esperienza in cui mi sono sentita messa nelle
                  condizioni di riconnettermi con il mio corpo, con ogni sua
                  parte, prima ancora di servirmene per fare movimento. La
                  lezione mi ha fatto venire voglia di andare sempre più avanti
                  in questo percorso di autoconsapevolezza.
                </div>
                {/* <div
                  className={classes.itemAvatar}
                  style={{
                    background: `url(${Avatar}) no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                ></div> */}
                <div className={classes.itemSignature}>
                  Maria, Gualdo Tadino (PG)
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;

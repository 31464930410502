import React from 'react';
import { createUseStyles } from 'react-jss';

import Button from '@components/Button/Button';

import Image from '@static/img/home-hero-2.jpg';
import ImageMobile from '@static/img/home-hero-2-mobile.jpg';
import ImageTablet from '@static/img/home-hero-2-tablet.jpg';
import Wave from '@static/img/onda-hero.svg';
import WaveTablet from '@static/img/onda-hero-tablet.svg';
import WaveMobile from '@static/img/onda-hero-mobile.svg';

import Styles from './Hero.styles';

const useStyles = createUseStyles(Styles);

const Hero = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.wave}>
        <div className={classes.waveContent}>
          <div className={classes.waveContentText}>
            <h1 className={classes.title}>
              Intuitive Eating Counselor
              <br />& Body Image Coach
            </h1>
            <p className={classes.subtitle}>Fai pace con corpo e cibo.</p>
            <Button to="/consulenza-gratuita" label="Consulenza gratuita" />
          </div>
        </div>
        <img className={classes.waveImage} alt="Liveflow" src={Wave} />
        <img
          className={classes.waveImageMobile}
          alt="Liveflow"
          src={WaveMobile}
        />
        <img
          className={classes.waveImageTablet}
          alt="Liveflow"
          src={WaveTablet}
        />
      </div>

      <div
        className={classes.image}
        style={{
          background: `url(${Image}) no-repeat transparent center center`,
          backgroundSize: 'cover',
        }}
      ></div>

      <div
        className={classes.imageMobile}
        style={{
          background: `url(${ImageMobile}) no-repeat transparent center center`,
          backgroundSize: 'cover',
        }}
      ></div>

      <div
        className={classes.imageTablet}
        style={{
          background: `url(${ImageTablet}) no-repeat transparent center center`,
          backgroundSize: 'cover',
        }}
      ></div>
    </div>
  );
};

export default Hero;

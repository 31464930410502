import Breakpoints from '@utils/breakpoints';
import Colors from '@utils/colors';
import Dimensions from '@utils/dimensions';

const Styles = {
  container: {
    padding: '100px 20px',
    background: Colors.alternateBg,
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '60px 0',
    },
  },
  content: {
    maxWidth: Dimensions.contentMaxWidth,
    margin: '0 auto',
  },
  title: {
    fontSize: '28px',
    fontWeight: 400,
    marginBottom: '10px',
    marginTop: '0',
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '22px',
      textAlign: 'center',
    },
  },
  links: {
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  link: {
    marginBottom: '10px',
  },
  linkG: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  col: {
    width: '25%',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '50%',
      textAlign: 'center',
    },
  },
};

export default Styles;

import Breakpoints from '@utils/breakpoints';

import Colors from '@utils/colors';

const Styles = {
  container: {
    position: 'relative',
    color: Colors.white,
    margin: '60px 0',
  },
  bg: {
    width: '100%',
    [`@media (max-width: ${Breakpoints.s})`]: {
      display: 'none',
    },
  },
  bgMobile: {
    display: 'none',
    [`@media (max-width: ${Breakpoints.s})`]: {
      display: 'block',
      width: '100%',
    },
  },
  title: {
    margin: '0 0 40px 0',
    fontSize: '32px',
    fontWeight: 'bold',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '25px',
      marginBottom: '30px',
    },
  },
  content: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  block: {
    display: 'block',
    width: '570px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '230px',
    },
  },
  carousel: {
    width: '100%',
  },
  itemText: {
    fontSize: '24px',
    margin: '0 auto',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '18px',
    },
  },
  itemContainer: {
    width: '100%',
    marginBottom: '40px',
    minHeight: '250px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginBottom: '0px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      minHeight: '150px',
    },
  },
  itemAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    margin: '20px auto',
  },
  itemSignature: {
    marginTop: '20px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '14px',
    },
  },
};

export default Styles;

import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './HomeBlog.styles';

const useStyles = createUseStyles(Styles);

const HomeBlog = ({ posts, ...props }) => {
  const classes = useStyles(props);

  const firstPost = posts[0] ? posts[0].node.frontmatter : null;
  const secondPost = posts[1] ? posts[1].node.frontmatter : null;
  const thirdPost = posts[2] ? posts[2].node.frontmatter : null;

  const firstPostImage = firstPost.image
    ? firstPost.image.replace('static/', '')
    : null;
  const secondPostImage = secondPost.image
    ? secondPost.image.replace('static/', '')
    : null;
  const thirdPostImage = thirdPost.image
    ? thirdPost.image.replace('static/', '')
    : null;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3 className={classes.title}>Dal blog</h3>
        <div className={classes.blog}>
          <div className={classes.left}>
            {firstPost && (
              <a
                href={`/blog/${firstPost.category}/${firstPost.path}`}
                key={firstPost.path}
                className={classes.firstBlog}
              >
                {firstPostImage && (
                  <img
                    src={`/${firstPostImage}`}
                    alt={firstPost.title}
                    className={classes.firstBlogImage}
                  />
                )}
                <h3 className={classes.firstBlogTitle}>{firstPost.title}</h3>
                <div className={classes.firstBlogDescription}>
                  {firstPost.description.substr(0, 600)}...
                </div>
                <div className={classes.firstBlogLinkContainer}>
                  <span className={classes.firstBlogLink}>LEGGI</span>
                </div>
              </a>
            )}
          </div>
          <div className={classes.right}>
            <div className={classes.top}>
              {secondPost && (
                <a
                  href={`/blog/${secondPost.category}/${secondPost.path}`}
                  key={secondPost.path}
                  className={classes.secondBlog}
                >
                  <div className={classes.secondBlogContent}>
                    {secondPostImage && (
                      <img
                        src={`/${secondPostImage}`}
                        alt={secondPost.title}
                        className={classes.secondBlogImage}
                      />
                    )}
                    <div className={classes.secondBlogText}>
                      <h3 className={classes.secondBlogTitle}>
                        {secondPost.title}
                      </h3>
                      <div className={classes.secondBlogDescription}>
                        {secondPost.description.substr(0, 80)}...
                      </div>
                      <div className={classes.secondBlogLinkContainer}>
                        <span className={classes.secondBlogLink}>LEGGI</span>
                      </div>
                    </div>
                  </div>
                </a>
              )}
            </div>
            <div className={classes.bottom}>
              {thirdPost && (
                <a
                  href={`/blog/${thirdPost.category}/${thirdPost.path}`}
                  key={thirdPost.path}
                  className={classes.thirdBlog}
                >
                  <div className={classes.thirdBlogContent}>
                    {thirdPostImage && (
                      <img
                        src={`/${thirdPostImage}`}
                        alt={thirdPost.title}
                        className={classes.thirdBlogImage}
                      />
                    )}
                    <div className={classes.thirdBlogText}>
                      <h3 className={classes.thirdBlogTitle}>
                        {thirdPost.title}
                      </h3>
                      <div className={classes.thirdBlogDescription}>
                        {thirdPost.description.substr(0, 150)}...
                      </div>
                      <div className={classes.thirdBlogLinkContainer}>
                        <span className={classes.thirdBlogLink}>LEGGI</span>
                      </div>
                    </div>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeBlog.propTypes = {
  posts: PropTypes.array,
};

export default HomeBlog;

import Colors from '@utils/colors';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  container: {
    padding: '80px 0 0 0',
    textAlign: 'center',
    [`@media (max-width: ${Breakpoints.s})`]: {
      padding: '55px 0 0 0',
    },
  },
  title: {
    textAlign: 'center',
    margin: '0 0 30px 0',
    fontSize: '32px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      fontSize: '28px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      fontSize: '22px',
    },
  },
  subtitle: {
    maxWidth: '600px',
    margin: '0 auto 40px',
    padding: '0 20px',
  },
  itemContainer: {
    position: 'relative',
  },
  item: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    margin: '0 auto',
    zIndex: 200,
    position: 'relative',
  },
  itemBg: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    background: Colors.third,
    position: 'absolute',
    right: '-25px',
    top: '0',
    left: '0',
    margin: 'auto',
    zIndex: 100,
  },
  itemTxt: {
    color: Colors.accent,
    marginTop: '30px',
    fontSize: '16px',
  },
  arrow: {
    display: 'block',
    background: 'none',
    border: 'none',
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    outline: 'none',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    width: '12px',
  },
  arrowPrev: {
    left: '30px',
  },
  arrowNext: {
    right: '30px',
  },
};

export default Styles;
